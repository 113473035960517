export default function SkillTypeDropdown(args) {
  const skillTypes = args.skillTypes;

  const onOptionChangeHandler = (event) => {
    args.setSelectedSillType(event.target.value);
  };

  return (
    <div className="inline-flex rounded-md shadow-sm">
      <select
        onChange={onOptionChangeHandler}
        className="sm:invisible rounded-md"
      >
        {skillTypes.map((skill, index) => {
          return (
            <option className="" key={index}>
              {skill}
            </option>
          );
        })}
      </select>
    </div>
  );
}
