import { useState } from "react";
import myData from "../resources/data.js";
import SkillTypeDropdown from "./skillTypeDropdown";
import SkillTypeButtons from "./skillTypeButtons";

function SkillSection(args) {
  let [selectedSillType, setSelectedSillType] = useState("All");

  const skills = myData.skills;
  const skillTypes = ["All"].concat(myData.skillTypes);

  return (
    <section>
      <div>
        <h3 className="text-3xl py-2 dark:text-white">My Skills</h3>
        {/* <p className="text-md py-2 leading-8 text-gray-800">
          More <span className="text-teal-500">blue text</span>text....
        </p>
        <p className="text-md py-2 leading-8 text-gray-800">
          More <span className="text-teal-500">blue text</span>text....
        </p> */}
      </div>

      <SkillTypeButtons
        skillTypes={skillTypes}
        selectedSillType={selectedSillType}
        setSelectedSillType={setSelectedSillType}
      />
      <SkillTypeDropdown
        skillTypes={skillTypes}
        selectedSillType={selectedSillType}
        setSelectedSillType={setSelectedSillType}
      />

      <div className=" bg-gray-300 dark:bg-gray-700 py-3 mt-2 rounded-xl">
        <div className="grid mx-auto gap-1 px-5 grid-cols-2 min-[400px]:grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-10 sm:max-h-64 max-h-96 overflow-x-auto">
          {skills.map((skill, index) =>
            skill.type.concat(["All"]).includes(selectedSillType) ? (
              <div
                key={index}
                className="my-2 text-center shadow-lg p-3 rounded-xl bg-white min-w-24 max-w-28 "
              >
                <img
                  src={skill.icon}
                  height={50}
                  width={50}
                  className="m-auto"
                  alt="Skill"
                />
                <h3 className="text-lg font-medium pt-1 pb-2 text-wrap">
                  {skill.name}
                </h3>
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    </section>
  );
}

export default SkillSection;
