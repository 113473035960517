import "./App.css";
import { BsFillMoonStarsFill, BsFillSunFill } from "react-icons/bs";
import AboutMe from "./components/aboutMeSection";
import { useState } from "react";
import ProjectSection from "./components/projectSection";
import SkillSection from "./components/skillSection";
import resume from "./resources/Shubham_Chauhan_Resume.pdf";

function App() {
  const [darkMode, setDarkMode] = useState(true);
  return (
    <div className={darkMode ? "dark" : ""}>
      <div>
        <head>
          <title>Test title</title>
        </head>
      </div>
      <main className="bg-white px-2 md:px-4 lg:px-8 xl:px-10 dark:bg-gray-800">
        <section className="">
          <nav className="py-10 mb-2 flex justify-between">
            <h1 className="text-xl dark:text-white">Shubham Chauhan</h1>
            <ul className="flex items-center">
              <li>
                {darkMode ? (
                  <BsFillSunFill
                    className="cursor-pointer text-2xl text-white"
                    onClick={() => setDarkMode(!darkMode)}
                  />
                ) : (
                  <BsFillMoonStarsFill
                    className="cursor-pointer text-2xl"
                    onClick={() => setDarkMode(!darkMode)}
                  />
                )}
              </li>
              <li>
                <a
                  download={"Shubham_Chauhan_Resume.pdf"}
                  className="bg-gradient-to-r from-cyan-500 to-teal-500 text-white px-4 py-2 rounded-md ml-8"
                  href={resume}
                >
                  Resume
                </a>
              </li>
            </ul>
          </nav>
        </section>
        <AboutMe />

        <SkillSection />
        <ProjectSection />
        {/* <section>
          <div>
            <h3 className="text-3xl py-1 dark:text-white">Portfolio</h3>
            <p className="text-md py-2 leading-8 text-gray-800">
              More <span className="text-teal-500">blue text</span>text....
            </p>
            <p className="text-md py-2 leading-8 text-gray-800">
              More <span className="text-teal-500">blue text</span>text....
            </p>
          </div>
          <div className="flex gap-10 flex-col py-10 md:flex-row md:flex-wrap">
            <div className="basis-1/3 flex-1">
              <img
                src={webPic}
                className="rounded-lg object-cover"
                height={"100%"}
                width={"100%"}
              />
            </div>
            <div className="basis-1/3 flex-1">
              <img
                src={webPic}
                className="rounded-lg object-cover"
                height={"100%"}
                width={"100%"}
              />
            </div>
            <div className="basis-1/3 flex-1">
              <img
                src={webPic}
                className="rounded-lg object-cover"
                height={"100%"}
                width={"100%"}
              />
            </div>
            <div className="basis-1/3 flex-1">
              <img
                src={webPic}
                className="rounded-lg object-cover"
                height={"100%"}
                width={"100%"}
              />
            </div>
            <div className="basis-1/3 flex-1">
              <img
                src={webPic}
                className="rounded-lg object-cover"
                height={"100%"}
                width={"100%"}
              />
            </div>
          </div>
        </section> */}
      </main>
    </div>
  );
}

export default App;
