import myData from "../resources/data";
import { AiFillLinkedin } from "react-icons/ai";
import myPic from "../resources/myPic.png";

export default function AboutMe(args) {
  return (
    <section className="mb-10 lg:flex mt-16 mx-auto">
      <div className="mb-5 pl-28">
        <div className="text-center mb-5 lg:text-left">
          <h2 className="text-5xl py-2 text-teal-600 font-medium md:text-6xl">
            {myData.name}
          </h2>
          <h3 className="text-2xl py-3 md:text-3xl dark:text-white">
            {myData.jobTitle}
          </h3>
          <p className="text-md py-5 leading-8 text-gray-800 md:text-xl max-w-lg mx-auto lg:mx-0 dark:text-gray-400 max-h-64 overflow-auto">
            {myData.aboutMe}
          </p>
        </div>
        <div className="text-5xl flex justify-center lg:justify-start lg:px-0 gap-16 pb-2 text-gray-600 dark:text-white">
          <a href="google.com">
            <AiFillLinkedin />
          </a>
          <a href="google.com">
            <AiFillLinkedin />
          </a>
          <a href="google.com">
            <AiFillLinkedin />
          </a>
        </div>
      </div>
      <div className="relative lg:mt-4 mx-auto bg-gradient-to-b from-teal-500 dark:from-teal-500 rounded-full w-64 h-96 overflow-hidden">
        <img src={myPic} alt="Me in 3D" />
      </div>
    </section>
  );
}
