import myData from "../resources/data.js";

function ProjectSection(args) {
  const projets = myData.projects;
  return (
    <section className="mt-10">
      <div>
        <h3 className="text-3xl py-1 dark:text-white">My Projects</h3>
        <p className="text-md py-2 leading-8 text-gray-800">
          More <span className="text-teal-500">blue text</span>text....
        </p>
        <p className="text-md py-2 leading-8 text-gray-800">
          More <span className="text-teal-500">blue text</span>text....
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  mx-auto">
        {projets.map((project, index) => (
          <div className="my-10  justify-self-center">
            <div className="text-center shadow-lg p-5 mx-2 rounded-xl dark:bg-white ">
              <img
                src={project.icon}
                height={100}
                width={100}
                className="m-auto"
                alt="Project"
              />
              <h3 className="text-lg font-medium pt-8 pb-2">{project.name}</h3>
              <p className="py-2">{project.discription}</p>
              <h4 className="py-4 text-teal-600">Tools used:</h4>
              {project.points.map((projectPoint, index) => (
                <p key={index} className="text-gray-800 py-1">
                  {projectPoint}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ProjectSection;
