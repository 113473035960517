export default function SkillTypeButtons(args) {
  const skillTypes = args.skillTypes;
  const numSkills = skillTypes.length;

  return (
    <div
      className="sm:inline-flex rounded-md shadow-sm hidden sm:visible"
      role="group"
    >
      {skillTypes.map((type, index) => (
        <button
          type="button"
          class={`px-4 py-2 text-sm font-medium border border-gray-900 
            ${index === 0 ? "rounded-s-lg" : ""} 
            ${index === numSkills - 1 ? "rounded-e-lg" : ""}
            ${
              args.selectedSillType === type
                ? "z-10 ring-2 ring-gray-500 bg-gray-700 text-white dark:bg-gray-700"
                : "text-gray-900 bg-transparent"
            }
             hover:bg-gray-900 hover:text-white focus:bg-gray-700 dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700`}
          onClick={() => args.setSelectedSillType(type)}
        >
          {type}
        </button>
      ))}
    </div>
  );
}
