let name = "Shubham Chauhan";
let aboutMe = `I'm Shubham Chauhan, with a Master's in Applied Computer Science from Dalhousie University. I excel in C/C++, Python, Java, and JavaScript, showcasing my skills through impactful projects and a role as a Co-op Software Developer at ESO, highlighting my dedication to tech innovation.`;
let jobTitle = "Fullstack Devloper";

let projects = [
  {
    name: "Project 1",
    discription: "asjdhasjh jhasdvas djsdjsa djhsavdas djhasvdasd jas d",
    icon: require("./images/web.png"),
    points: ["Ponit 1", "Point 2", "Point 3"],
  },

  {
    name: "Project 2",
    discription: "asjdhasjh jhasdvas djsdjsa djhsavdas djhasvdasd jas d",
    icon: require("./images/web.png"),
    points: ["Ponit 1", "Point 2", "Point 3"],
  },

  {
    name: "Project 3",
    discription: "asjdhasjh jhasdvas djsdjsa djhsavdas djhasvdasd jas d",
    icon: require("./images/web.png"),
    points: ["Ponit 1", "Point 2", "Point 3"],
  },
];

let skills = [
  {
    icon: require("./images/coding_1.png"),
    name: "C++",
    type: ["Languages", "Backend"],
  },
  {
    icon: require("./images/coding_1.png"),
    name: "C",
    type: ["Languages", "Backend"],
  },
  {
    icon: require("./images/python.png"),
    name: "Python",
    type: ["Languages", "Backend"],
  },
  {
    icon: require("./images/java.png"),
    name: "Java",
    type: ["Languages", "Backend"],
  },
  {
    icon: require("./images/javascript.png"),
    name: "JavaScript",
    type: ["Languages", "Web", "Frontend"],
  },
  {
    icon: require("./images/typescript.png"),
    name: "TypeScript",
    type: ["Languages", "Web", "Backend"],
  },
  {
    icon: require("./images/html.png"),
    name: "HTML",
    type: ["Languages", "Web", "Frontend"],
  },
  {
    icon: require("./images/css-3.png"),
    name: "CSS",
    type: ["Languages", "Web", "Frontend"],
  },
  {
    icon: require("./images/coding_1.png"),
    name: "React",
    type: ["Frameworks", "Web", "Frontend"],
  },
  {
    icon: require("./images/node-js.png"),
    name: "Node.js",
    type: ["Frameworks", "Web", "Backend"],
  },
  {
    icon: require("./images/coding_1.png"),
    name: "JUnit",
    type: ["Frameworks", "Backend", "Testing"],
  },
  {
    icon: require("./images/coding_1.png"),
    name: "Material-UI",
    type: ["Frameworks", "Frontend"],
  },
];

let skillTypes = ["Languages", "Web", "Frontend", "Backend", "Frameworks"];

let myData = {
  name,
  aboutMe,
  jobTitle,
  projects,
  skills,
  skillTypes,
};

export default myData;
